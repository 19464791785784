
import Hero from '../shared/Hero';
import AboutUsHeroBg from '../assets/about-hero-bg.svg';
import publicityPartnership from '../assets/partnership-publicity.svg';
import ourMission from '../assets/our-mission.svg';
import ourVisionImage from '../assets/out-of-the-ordinary-card.svg'

import { Col, Row } from 'reactstrap';
import BibleVerseCard from '../shared/BibleVerseCard';
const AboutPage = () => {
    return (
        <div className='partnership-page'>
            <Hero className="partnership-page-hero" backgroundImage={AboutUsHeroBg}>
            <BibleVerseCard verse="Psalm 85:10 (NIV)" passage="Love and faithfulness meet together; righteousness and peace kiss each other." />

            </Hero>
            <div className='mb-md-5  pb-md-5 mb-lg-5  pb-lg-5'>
                <h1 className='text-center about-title pt-5 pb-3'>Welcome To Anagkazo, The Mountain Of Encounters</h1>
                <div className='about-message-text mb-5'>
                    <p className='text-center'>
                        AMI is a non-denominational and an inter-denominational movement with the mission to raise watchmen, saviours and deliverers over families and territories via sharing life transforming truths through messages (audio, video, articles) with the world.
                    </p>
                </div>
            </div>
            <div className='mt-lg-5 px-md-5 px-lg-5 mb-md-5 mb-lg-5 '>
                <Row className='mb-md-5 mb-lg-5 mb-3 mx-0'>
                    <Col xs="12" md="4" className='about-image pt-5 px-3'>
                        <h4 className='apostle-title'>APOSTLE EFFA EMANUEL ISAAC</h4>
                        <img src={publicityPartnership} alt='' />
                    </Col>
                    <Col xs="12" md="8" className='pt-lg-5 pt-md-5 ps-md-5 pt-3 px-md-5 px-lg-5 px-5'>
                        <div>
                            <h4 className='apostle-title'>APOSTLE EFFA EMANUEL ISAAC</h4>
                            <div className='about-item-text'>
                                <p>
                                    Apostle Effa Emmanuel Isaac is a Writer, Speaker, and President of Anagkazo Mission International; a non-denominational and an inter-denominational movement based in Kaduna State, Nigeria. Through his weekly Friday meetings, Apostle Effa Emmanuel Isaac mentors and ministers to thousands of men and women, young and old, onsite and globally, shaping their paradigms and preparing them for what he calls “Maximum Kingdom Impact”.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='mb-md-5 mb-lg-5 mb-4 mx-0'>
                    <Col xs="12" md="8" className='pt-3 pt-md-5 pt-lg-5 ps-md-5 ps-lg-5 order-md-0 order-lg-0 order-1'>
                        <div className=' px-5'>
                            <h4 className='about-item-title'>Our Vision</h4>
                            <div className='about-item-text'>
                                <p>
                                    To raise kingdom functionaries and create sustainable systems that would equip the believer for an impactful life. Our Mission is:
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs="12" md="4" className='pt-md-5 pt-lg-5 px-3 about-image'>
                        <h4 className='about-item-title'>Our Mission</h4>
                        <img src={ourVisionImage} alt='our vision' />
                    </Col>
                </Row>
                <Row className='mb-md-5 mb-lg-5 mb-4 mx-0'>
                    <Col xs="12" md="4" className='pt-md-5 pt-lg-5 px-3 about-image'>
                        <h4 className='about-item-title'>Our Mission</h4>
                        <img src={ourMission} alt='our mission' />
                    </Col>
                    <Col xs="12" md="8" className='pt-3 pt-md-5 pt-lg-5 ps-md-5 ps-lg-5'>
                        <div>
                            <h4 className='about-item-title'>Our Mission</h4>
                            <div className='about-item-text'>
                                <p>
                                    <ol>
                                        <li>
                                            1. To raise Watchmen, Saviours and Deliverers over families and territories.
                                        </li>
                                        <li>
                                            2. To bring speed and settlement to God's people.
                                        </li>
                                        <li>
                                            3. To resist the spirits of confusion and their territorial initiatives.</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='mb-5 py-md-5 py-lg-5'>
                    <h3 className='text-center about-declaration-title'>Our Declaration</h3>
                    <p className='about-declaration-text text-center w-70'>
                        I am an ambassador of God's kingdom; a part of a generation that is empowered, motivated and stirred to operate with supernatural excellence and virtue. I commit to live an exemplary lifestyle of leadership and excellence and to do all within my power to advance God's kingdom. First in the hearts of people and across my territory and sphere of influence.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;
