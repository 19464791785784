import  { ReactNode } from "react";
import { IonIcon } from '@ionic/react';
import { call, location, mail } from 'ionicons/icons';
import pageFooterHeroBg from '../assets/page-footer-hero-bg.svg'
type FooterHeroProps = {
    children?: ReactNode;
    buttonText?: string;
    buttonLink?: string;
    className?: string;
    backgroundImage?: string;
};



const FooterHero = ({
    children,
    className
}: FooterHeroProps) => {
  return (
    <div className={`page-footer-hero-bg px-0 ${className}`} style={{ backgroundImage: `url(${pageFooterHeroBg})` }}>
      <div className="px-md-5 px-lg-5 mb-5 h-100 ">
        {children}
        <div className='org-address-container h-100 py-md-5 ps-4 w-xs-100 pe-md-5 pe-lg-5'>
          <div className='d-flex justify-content-start py-0 my-0'>
            <span className="address-icon pe-2 pt-md-2 pt-lg-2"><IonIcon icon={location} /></span>
            <p className="address-text py-0 ">No. 48-49 Chiroma street, Ungwan Yelwa, Television, Kaduna</p>
          </div>
          <div className='d-flex justify-content-start py-0 my-0'>
            <span className="address-icon pe-2 pt-md-2 pt-lg-2"><IonIcon icon={call} /></span>
            <p className="address-text py-0 ">+234 906 9108 702</p>
          </div>
          <div className='d-flex justify-content-start py-0 my-0'>
            <span className="address-icon pe-2 pt-md-2 pt-lg-2 "><IonIcon icon={mail} /></span>
            <p className="address-text py-0 ">ami@anagkazo.com</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterHero;


