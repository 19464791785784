import Hero from '../shared/Hero';
import IamNewHeroBg from '../assets/iam-new-hero-bg.svg'
import iamNewImage from '../assets/iam-new-image.svg'
import { Col, Row } from 'reactstrap';
import FooterHero from '../shared/FooterHero';
import BibleVerseCard from '../shared/BibleVerseCard';
const NewPersonPage = () => {
    return (
        <div>
            <Hero className="prayer-request-page-hero" backgroundImage={IamNewHeroBg}>
                <BibleVerseCard verse="Psalm 85:10 (NIV)" passage="Love and faithfulness meet together; righteousness and peace kiss each other." />
            </Hero>
            <h1 className='text-center iam-new-page-title pt-5 pb-3'>EXPERIENCE ANAGKAZO (the compelling power of the Holy Spirit)</h1>
            <div className='prayer-request-message-text'>
                <p className=''>
                    Welcome to our fellowship! We are a community of individuals who share a passion for growth, collaboration, and support. As a new member, you are now part of a network of people who will inspire, motivate and challenge you. We encourage you to share your experiences and unique perspective. Let's start this adventure together with curiosity, positivity, and a growth mindset. Welcome aboard!
                </p>
            </div>
            <div className='mt-5 px-md-5 px-lg-5 px-3 mb-5 pt-5'>
                <Row className='mb-5 pt-5'>
                    <Col xs="12" md="4" className='image-column' >
                        <div>
                        <h4 className='new-joiner-item-title text-center'>Join us on Sunday</h4>
                        <p className='meeting-address mx-2'>No. 48-49 Chiroma street, Ungwan Yelwa, Television, Kaduna</p>
                        </div>
                        <img src={iamNewImage} alt='' />
                    </Col>
                    <Col xs="12" md="8" className='pt-5 ps-md-5 ps-lg-5'>
                        <div>
                            <div className='address-wrapper'>
                            <h4 className='new-joiner-item-title'>Join us on Sunday</h4>
                            <p className='meeting-address'>No. 48-49 Chiroma street, Ungwan Yelwa, Television, Kaduna</p>
                            </div>
                            <div className='meeting-iam-new-text'>
                                <p>EXPERIENCE ANAGKAZO (the compelling power of the Holy Spirit) with us every Friday by 5pm on site and online.</p>
                                <p>
                                    Join us every Tuesday by 5pm at CONGRESS (The Gathering of Legislators) Intercessory & Communion Service, onsite and online
                                </p>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
            <Row className='px-0 px-md-5 px-lg-5 mb-5 mx-0'>
                <FooterHero className="page-footer-hero-bg mb-0 pt-0" />
            </Row>
        </div>
    )
}


export default NewPersonPage;
