import React from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    AccordionProps,
    UncontrolledAccordion,
} from 'reactstrap';
interface AccordionItemRowProps {
    items: {
        id: string;
        title: string;
        content: React.ReactNode;
    }[];
    flush?: boolean;
}

interface AccordionItemRowProps extends AccordionProps {
    // Define the toggle prop as a function that accepts a string parameter
    toggle: (id: string) => void;
}




function AccordionComponent({ items, flush, toggle, open }: AccordionItemRowProps) {
    return (
        <div>
            <UncontrolledAccordion defaultOpen="1">
                {
                    // @ts-ignore
                    <Accordion flush={flush} open={open} toggle={toggle}>
                        {items.map((item) => (
                            <AccordionItem key={item.id}>
                                <AccordionHeader className='bank-details-title' targetId={item.id}>{item.title}</AccordionHeader>
                                <AccordionBody accordionId={item.id}>{item.content}</AccordionBody>
                            </AccordionItem>
                        ))}
                    </Accordion>

                }
            </UncontrolledAccordion>
        </div>
    );
}

export default AccordionComponent;
