import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Row,
    Container,
    Col
} from 'reactstrap';
import SocialIconComponent from './SocialIcon';
import logo from '../assets/anagkazo.svg'
import { logoFacebook, logoInstagram, logoLinkedin, logoTwitter } from 'ionicons/icons';
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const links = [
        {
            iconName: logoFacebook,
            href: 'https://www.facebook.com/'
        },
        {
            iconName: logoTwitter,
            href: 'https://twitter.com/'
        },
        {
            iconName: logoInstagram,
            href: 'https://www.instagram.com/'
        },
        {
            iconName: logoLinkedin,
            href: 'https://www.linkedin.com/'
        }
    ];

    return (
        <footer className="footer py-4">
            <Container className="px-md-0 ml-auto pt-4" fluid="md">
                <Row>
                    <Col xs="12" md="5">
                        <NavbarBrand href="/">
                            <img src={logo} alt="Anagkazo logo" width="120" height="120" />
                        </NavbarBrand>
                        <p className='footer-description'>Unlock your potential and fulfill your destiny with Anagkazo! Let this powerful word ignite a fire within you and propel you towards success.</p>
                    </Col>
                    <Col className="footer-navbar">
                        <Navbar className="ps-md-0 text-right">
                            <Nav className="ps-0" navbar>
                                <NavItem>
                                    <NavLink tag={Link}  to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/about">About Us</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/partnership">Partnership</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/sermons">Sermons</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/contact">Contact Us</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/give">Give</NavLink>
                                </NavItem>
                            </Nav>
                        </Navbar>
                        <div>
                            <p className='connect-with-us-title'>Connect with Us</p>
                            <div>
                                <Row>
                                    <Col>
                                        {links.map((link) => (
                                            <SocialIconComponent
                                                iconName={link.iconName}
                                                href={link.href}
                                                key={link.href}
                                            />
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col className="pt-3">
                        <div>
                            <p className='title-footer p-0 m-0'>Location</p>
                            <p className='footer-description pe-5'>
                                No. 48-49 Chiroma Street, Anguwan Yelwa, Television, Kaduna
                            </p>
                            <p className='title-footer p-0 m-0'>Phone Number</p>
                            <p className='footer-description'>+234 906 910 870 2 </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className='page-copy m-0'>&copy; {currentYear} Anagkazo. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
