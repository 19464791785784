import { Row, Col, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import UpcomingImage from '../assets/narayi-crusade.svg'
import { pinOutline, calendar, arrowForwardCircle } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

interface UpcomingEvent {
    title: string;
    date: string;
    description: string;
    location?: string
}

const UpcomingEventCard: React.FC<UpcomingEvent> = ({ date, title, description, location }) => {
    const shortDescription = description.split(' ').slice(0, 30).join(' ');
    const truncatedDescription = description.length > 30 ? `${shortDescription}...` : description;

    return (
        <Card className="mb-0 border-0 upcoming-event-card">
            <Row noGutters>
                <Col xs={12} md={5}>
                    <CardImg top width="100%" src={UpcomingImage} alt={`Upcoming Event ${title}`} style={{ objectFit: 'cover' }} />
                </Col>
                <Col xs={12} md={7}>
                    <CardBody>
                        <CardTitle tag="h5" className="mb-1">{title}</CardTitle>
                        {location && (
                            <div className='d-flex justify-content-start py-0 my-0'>
                                <span className="event-date-icon pe-2 py-0 my-0"><IonIcon icon={pinOutline} /></span>
                                <p className="event-date py-0 my-0">{location}</p>
                            </div>
                        )}
                        <div className='d-flex justify-content-start py-0 my-0'>
                            <span className="event-date-icon pe-2"><IonIcon icon={calendar} /></span>
                            <p className="event-date py-0 my-0">{date}</p>
                        </div>
                        <CardText className="event-description-short mb-3">{truncatedDescription}</CardText>
                        <div className='event-read-more d-flex'>
                            <a href='www' className='me-3'>Read more</a>
                            <span><IonIcon icon={arrowForwardCircle} /></span>
                        </div>
                    </CardBody>
                </Col>
            </Row>
        </Card>
    );
};

export default UpcomingEventCard;
