import { FC, ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import outOfTheOrdinaryHeroBg from '../assets/out-of-the-ordinary-hero.svg'
import outOfOrdinaryCard from '../assets/out-of-the-ordinary-card.svg'
import MessageCard from '../shared/SermonCard';
import YoutubeIcon from '../assets/youtube-icon.svg'
type SermonProps = {
    name: string;
    id: number;
}
type BibleVerse = {
    title: string;
    date: string;
    author: string;
    verse: string;
    imageUrl: string;
};


const bibleVerses: BibleVerse[] = [
    {
        title: 'Out of the ordinary',
        date: 'March 15, 2023',
        author: 'James Wilson',
        verse: 'John 11:25-26',
        imageUrl: outOfOrdinaryCard,
    },
    {
        title: 'Out of the ordinary',
        date: 'March 14, 2023',
        author: 'Sarah Lee',
        verse: 'Jeremiah 29:11',
        imageUrl: outOfOrdinaryCard,
    },
    {
        title: 'Out of the ordinary',
        date: 'March 13, 2023',
        author: 'David Jones',
        verse: 'Romans 3:23-24',
        imageUrl: outOfOrdinaryCard,
    },

]

const SermonDetailsPage: FC<SermonProps> = (): ReactElement => {
    return <>
        <div className='text-center py-4'>
            <p className='sermon-detail-date py-0 my-0'>
                Jan 23, 2023
            </p>
            <p className='sermon-detail-title py-0 my-0'>
                Out of the ordinary
            </p>
            <p className='sermon-detail-author py-0 my-0'>
                Pst Yemi Adekola
            </p>
        </div>
        <div className="sermon-detail-page-hero">
            <img src={outOfTheOrdinaryHeroBg} alt="message featured banner" />
        </div>
        <a className="d-flex  mb-3 message-download-container px-3" href='https://youtube.com/'>
            <div className="me-auto px-md-2 mt-3 listen-text">Also Listen to the recording here</div>
            <div className="px-md-2">
                <img src={YoutubeIcon} alt="Youtube" className='h-md-100 youtube-icon h-lg-100 h-50 mt-3' />
            </div>
        </a>
        <div className='px-md-5 px-lg-5 px-4'>
            <div className='sermon-container pt-4'>
                <div className='sermon-content pb-5 mx-auto'>
                    The Bible is full of stories of the extraordinary and miraculous, from the parting of the Red Sea to the resurrection of Jesus Christ. But there are also many lesser-known stories that are equally out of the ordinary.

                    One such story is that of Enoch, a man who "walked with God" and was taken up to heaven without experiencing death. According to the book of Genesis, Enoch was the father of Methuselah and lived for 365 years before he was taken by God. The book of Hebrews describes Enoch as someone who pleased God and was rewarded by being taken to heaven without dying.

                    Another unusual story from the Bible is that of the prophet Jonah, who was swallowed by a giant fish and survived for three days before being spit out onto the shore. The book of Jonah tells the story of how God instructed Jonah to go to the city of Nineveh to preach repentance, but Jonah refused and fled on a ship. When a storm threatened to sink the ship, Jonah realized that he was the cause and allowed himself to be thrown overboard, where he was swallowed by a fish. After three days, the fish vomited Jonah onto the shore, and he finally went to Nineveh to deliver God's message.

                    The book of Daniel also contains several out-of-the-ordinary stories, such as the account of Daniel's three friends, Shadrach, Meshach, and Abednego, who refused to bow down to a golden statue and were thrown into a fiery furnace as punishment. However, God protected them, and they emerged from the furnace unharmed.

                    In addition to these stories, the Bible is also full of miracles performed by Jesus, such as healing the sick, feeding the multitudes with a few loaves and fish, and walking on water. These miracles demonstrate Jesus' divinity and serve as a reminder of the power of God to do the impossible.

                    In conclusion, the Bible is full of stories that are out of the ordinary, from Enoch's journey to heaven to Jonah's survival in the belly of a fish. These stories remind us of the power of God and the extraordinary things that can happen when we put our faith in Him.
                </div>
            </div>
        </div>
        <div className='mt-md-3 px-md-5 mx-md-0 mt-lg-3 px-lg-5 mx-lg-0 mx-4'>
            <h4 className='other-sermons-title my-3'>Other Sermons</h4>
            <Row >
                {bibleVerses.map((verse, index) => (
                    <Col key={index} xs="12" md="4" className='mb-5'>
                        <MessageCard
                            title={verse.title}
                            date={verse.date}
                            author={verse.author}
                            verse={verse.verse} imageUrl={verse.imageUrl} />
                    </Col>
                ))}
            </Row>
        </div>
    </>
}

export default SermonDetailsPage
