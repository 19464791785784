import React from "react";
import { NavLink as RRNavLink, useLocation } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";

interface NavLinksProps {
  to: string;
  exact?: boolean;
  className?: string;
  children:
    | string
    | React.ReactNode
    | ((isActive: boolean) => React.ReactNode);
}

const ButtonNavLink = ({ to, exact, className='pt-0', children }: NavLinksProps) => {
  const { pathname } = useLocation();
  const isActive = pathname === to;

  return (
    <NavItem className={className}>
      <NavLink  className="pt-1" tag={RRNavLink} to={to} exact={exact} activeClassName="active">
        {typeof children === "function" ? children(isActive) : children}
      </NavLink>
    </NavItem>
  );
};


export default ButtonNavLink;
