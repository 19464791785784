import Hero from '../shared/Hero';
import backgroundImage from '../assets/home-page-hero.svg';
import { Button, Col, NavLink, Row } from 'reactstrap';
import CustomImageCard from '../shared/CustomImageCard';
import prayerImage from "../assets/prayer-image.svg";
import partnershipImage from '../assets/partnership-image.svg'
import testimonyImage from '../assets/testimony-image.svg'
import OutOfTheOrdinary from '../assets/out-of-the-ordinary.svg'
import DateTitleListGroup from '../shared/DateTitleListGroup';
import UpcomingEventCard from '../shared/EventCards';
import BottomSectionHero from '../assets/home-page-section-hero-bg.svg'
import { Link } from 'react-router-dom';
import BibleVerseCard from '../shared/BibleVerseCard';


const HomePage = () => {
    const events = [
        { date: "2023-03-15", title: "How to Fast and Pray", link: "" },
        { date: "2023-03-20", title: "Praying with the scriptures", link: "" },
        { date: "2023-03-25", title: "Jesus Loves you", link: "" },
        { date: "2023-03-28", title: "Be Steadfast", link: "" },
        { date: "2023-03-20", title: "Praying with the scriptures", link: "" },
    ];
    const eventData = [
        {
            title: "Narayi crusade and outreach",
            date: "25th - 26th March 2023",
            location: "Narayi, Kaduna",
            description: "Join us for a powerful crusade and outreach event in Narayi, Kaduna. Experience the power of God in a life-changing way!"
        },
        {
            title: "Women's conference",
            date: "7th - 9th April 2023",
            location: "Abuja",
            description: "Ladies, get ready for an unforgettable weekend of inspiration, worship, and empowerment. Join us at our annual Women's Conference in Abuja."
        },
        {
            title: "Mighty men's conference",
            date: "14th - 16th April 2023",
            location: "Lagos",
            description: "Men, it's time to take your place as the mighty warriors God created you to be. Join us at the Mighty Men's Conference in Lagos for"
        },
        {
            title: "Youth camp",
            date: "20th - 25th July 2023",
            description: "Are you ready for an epic summer adventure? Join us at our Youth Camp in Jos for a week of fun, friendship, and spiritual growth."
        }
    ];

    return (
        <div className='home-page'>
            <Hero
                title="Experience Anagkazo (the compelling power of the Holy Spirit) with us every Friday by 5pm on site and online."
                subtitle="Join us every Tuesday by 5pm at CONGRESS (The Gathering of Legislators) Intercessory & Communion Service. onsite and online"
                buttonText="Learn More"
                buttonLink="/about"
                className="home-page-hero"
                backgroundImage={backgroundImage}
            >
                <div className="button-container">
                    <Button
                        className="me-4 button"
                        style={{
                            width: '153px',
                            height: '59px',
                            background: '#A27C3B',
                            borderRadius: '4px',
                            color: 'white'
                        }}
                    >
                        <NavLink tag={Link} to="/iam-new" >
                            I'm New
                        </NavLink>
                    </Button>
                    <Button
                        outline
                        style={{
                            width: '153px',
                            height: '59px',
                            border: '2px solid #A27C3B',
                            color: '#fff',
                            borderRadius: '4px'
                        }}
                    >
                        <NavLink tag={Link} to="/sermons" >
                            Sermons
                        </NavLink>
                    </Button>
                </div>
            </Hero>
            <div className='mb-3'>
                <Row xs={1} md={3} className='mx-0'>
                    <Col>
                        <CustomImageCard
                            buttonText="Share with us"
                            buttonLink="/#/testimony"
                            className='normal-card posts-container'
                            imageUrl={testimonyImage}
                            title="Share Testimonies"
                            subtitle="GOD IS GREAT"
                        />
                    </Col>
                    <Col>
                        <CustomImageCard
                            buttonText="Let Us Pray"
                            buttonLink="/#/prayer-request"
                            className='normal-card posts-container'
                            imageUrl={prayerImage}
                            title="Prayer Request"
                            subtitle="WE INTERCEDE"
                        />
                    </Col>
                    <Col>
                        <CustomImageCard
                            buttonText="Start Here"
                            className='normal-card posts-container'
                            buttonLink="/#/partnership"
                            imageUrl={partnershipImage}
                            title="Partner with us"
                            subtitle="HELPING  HANDS"
                        />
                    </Col>
                </Row>
            </div>
            <p className="text-center sermons-title-text mb-5">Sermons</p>
            <div className="px-0 mx-md-5 mx-lg-5 mx-3 mb-5">
                <Row xs="1" md="2" className="g-5 mx-0">
                    <Col>
                        <article className="message">
                            <div className="out-of-the-ordinary" style={{ backgroundImage: `url(${OutOfTheOrdinary})` }}>
                                <div className='message-small-card px-3 pt-2 p-md-5 p-lg-5'>
                                    <header>
                                        <h2 className="message-title my-md-1 my-lg-1 my-0">Out of the ordinary</h2>
                                        <p className="message-date my-md-1 my-lg-1 my-0">Jan 23, 2023</p>
                                    </header>
                                    <div className="message-content">
                                        <p className="message-passage my-md-1 my-lg-1 my-0">Matthew 3: 10 - 21</p>
                                        <p className="message-speaker my-md-1 my-lg-1 my-0">By Pst Yemi Adekola</p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </Col>

                    <Col>
                        <div className="d-flex mb-3">
                            <div className="me-auto p-2 more-sermons-text">More Sermons</div>
                            <div className="p-2 view-all-text">View All</div>
                        </div>
                        <div >
                            <DateTitleListGroup items={events} />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='px-0 mx-5 mb-5'>
                <p className="join-us-text py-0 m-0">
                    JOIN US FOR OUR
                </p>
                <p className="upcoming-event-text py-0 mb-0">Upcoming Events</p>
            </div>
            <div className="px-0 mx-5 mb-5">
                <Row xs="1" md="2" className="g-5">
                    {eventData.map((event, index) => (
                        <Col key={index} className="">
                            <UpcomingEventCard title={event.title} date={event.date} description={event.description} location={event.location} />
                        </Col>
                    ))}
                </Row>
            </div>
            <div className='page-bottom-section-hero px-md-0 mx-md-5 px-lg-0 mx-lg-5' style={{
                backgroundImage: `url(${BottomSectionHero})`
            }}>
               <BibleVerseCard verse="Psalm 85:10 (NIV)" passage="Love and faithfulness meet together; righteousness and peace kiss each other." />
            </div>
        </div>
    );
};

export default HomePage;
