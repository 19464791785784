import { HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './shared/Footer';
import HeaderNavBar from './shared/HeaderNavbar';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import GivenPage from './pages/GivenPage';
import HomePage from './pages/HomePage';
import PartnershipPage from './pages/PartnershipPage';
import SermonPage from './pages/SermonPage';
import PrayerRequestPage from './pages/PrayerRequestPage';
import TestimonyPage from './pages/TestimonyPage';
import NewPersonPage from './pages/NewPersonPage';
import SermonDetailsPage from './pages/SermonDetailsPage';

function App(): JSX.Element {
  return (
    <HashRouter>
      <div className="App">
        {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
          <HomePage />
        ) : (
          <div>
            <header className='header'>
              <HeaderNavBar />
            </header>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />}/> 
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/partnership" element={<PartnershipPage />}/> 
            <Route path="/prayer-request" element={<PrayerRequestPage />}/> 
            <Route path="/testimony" element={<TestimonyPage />}/> 
            <Route path="/give" element={<GivenPage />} />
            <Route path="/iam-new" element={<NewPersonPage />} />
            <Route path="/sermons" element={<SermonPage name={''} id={0} />}/> 
            <Route path="/sermons/:sermonId" element={<SermonDetailsPage name={''} id={0}/>}/> 
          </Routes>
            <Footer />
          </div>
        )}
      </div>
    </HashRouter>
  );
}

export default App;
