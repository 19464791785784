import  { ReactNode } from "react";
import { Container } from "reactstrap";

type HeroProps = {
    title?: string;
    subtitle?: string;
    children?: ReactNode;
    buttonText?: string;
    buttonLink?: string;
    className?:string;
    backgroundImage?: string;
};

const Hero = ({
    title,
    subtitle,
    children,
    backgroundImage,
    className
}: HeroProps) => {
    return (
        <div
            className={`d-flex justify-content-center pt-5 ${className}`}
            style={{
                backgroundImage: `url(${backgroundImage})`,
            }}
        >
          {(title || subtitle || children) && <Container className="pt-md-5 pt-lg-5">
                {title && <h1 className="display-3 title">{title}</h1>}
                {subtitle && <p className="lead subtitle">{subtitle}</p>}
                {children && children}
            </Container>}
        </div>
    );
};

export default Hero;
