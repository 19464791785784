import React, { Fragment } from 'react';
import {IonIcon} from '@ionic/react'
interface Props {
  href: string;
  iconName: string;
}

const SocialIconComponent: React.FC<Props> = ({ href='#', iconName = '' }) => {
  return (
    <Fragment>
      <a className="social-icon text-center pt-1" href={href} role='button'>
        <IonIcon icon={iconName}/>
      </a>
    </Fragment>
  );
};

export default SocialIconComponent;
