import { IonIcon } from "@ionic/react";
import { chevronBackCircle, chevronForwardCircleOutline } from "ionicons/icons";
import { Col, Row } from "reactstrap";

interface TestimonialProps {
    title: string;
    text: string;
    author: string;
  }
  
  interface TestimonialsProps {
    testimonialData: TestimonialProps[];
  }
  
  const Testimonials: React.FC<TestimonialsProps> = ({ testimonialData }) => {
    return (
      <Row className="mt-5">
        <div className="mt-5 px-5 mb-5">
          <Row>
            <Col md="8">
              <p className="testimony-title-header">God has been faithful</p>
              <p className="testimony-text">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium is mani doloremi quelaudantium, totamamiun isi remaperiam
                eaque ipsa quae ab illo.
              </p>
            </Col>
            <Col className="text-end pt-4">
              <div className="d-flex flex-row-reverse">
                <span>
                  <IonIcon icon={chevronBackCircle} />
                </span>
                <span>
                  <IonIcon icon={chevronForwardCircleOutline} />
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            {testimonialData.map(({ title, text, author }, index) => (
              <Col key={index} xs="12" md="4">
                <div className="">
                  <p className="testimony-title">{title}</p>
                  <p className="testimony-text">{text}</p>
                  <p className="testimony-author">{author}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Row>
    );
  };
  
 export default Testimonials;