import React from 'react';
import { Button } from 'reactstrap';

type CardProps = {
  imageUrl: string;
  buttonText: string;
  title: string;
  subtitle: string;
  className?: string;
  buttonLink: string;
};

const CustomImageCard: React.FC<CardProps> = ({
  imageUrl,
  className,
  buttonText,
  buttonLink,
  title,
  subtitle,
}) => {
  return (
    <div
      className={`d-flex mb-md-0 mb-4 flex-column align-items-center justify-content-center ${className}`}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <p className="text-center card-subtitle">{subtitle}</p>
      <p className="text-center card-title">{title}</p>
      <Button color="primary" className="card-button mt-3" href={buttonLink}>
        {buttonText}
      </Button>
    </div>
  );
};

export default CustomImageCard;
