import React, { FC } from 'react';

interface BibleVerseCardProps {
  verse: string;
  passage: string;
}

const BibleVerseCard: FC<BibleVerseCardProps> = ({ verse, passage }) => {
  return (
    <div className="message-small-card p-md-5 p-lg-5 ps-3 mb-0 mx-0 mt-0">
      <div className="message-content">
        <p className="message-title my-1">{verse}</p>
        <p className="message-passage my-1">{passage}</p>
      </div>
    </div>
  );
};

export default BibleVerseCard;
