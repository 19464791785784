import Hero from '../shared/Hero';
import partnershipHeroBg from '../assets/partnership-image.svg'
import PrayerRequestForm from '../shared/PrayerRequestForm';
import { Row } from 'reactstrap';
import FooterHero from '../shared/FooterHero';
import BibleVerseCard from '../shared/BibleVerseCard';
const PrayerRequestPage = () => {
    return (
        <div>
            <Hero className="prayer-request-page-hero" backgroundImage={partnershipHeroBg}>
                <BibleVerseCard verse="James 5:16" passage="Confess your faults one to another, and pray one for another, that ye may be healed. The effectual fervent prayer of a righteous man availed much. " />
            </Hero>
            <h1 className='text-center prayer-request-title pt-5 pb-3'>Prayer Request</h1>
            <div className='prayer-request-message-text container-lg'>
                <p className=''>
                    we invite you to partner with our ministry and help us make a positive impact in the lives of those we serve. Your support will make a significant difference in our ability to fulfill our mission of spreading the love of Christ to those in need.
                </p>
            </div>
            <div className='mt-5 px-md-5 px-lg-5 mb-5 container-lg'>
                <PrayerRequestForm />
            </div>
            <Row className='px-0 px-md-5 px-lg-5 mx-0'>
                <FooterHero className="page-footer-hero-bg mb-0 pt-0" />
            </Row>
        </div>
    )
}


export default PrayerRequestPage;
