import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const fullName = formData.get('fullName') as string;
    const email = formData.get('email') as string;
    const address = formData.get('address') as string;
    const phoneNumber = formData.get('phoneNumber') as string;
    const partnershipMode = formData.get('partnershipMode') as string;
    const organization = formData.get('organization') as string;
    console.log({fullName, email, address, phoneNumber, partnershipMode, organization});
    // perform submit action here, e.g. send data to server
    };

const PartnershipForm = () => {
  return (
    <Form className='mt-5 px-lg-0 px-md-0' onSubmit={handleSubmit}>
      <Row className='mb-0 mb-lg-3 mb-md-3'>
        <Col md={6}>
          <FormGroup>
            <Label for="fullName">
              Full Name
            </Label>
            <Input
              id="fullName"
              name="fullName"
              placeholder="John Moses"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="email">
              Email
            </Label>
            <Input
              id="email"
              name="email"
              placeholder="johnmoses@domain.com"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mb-0 mb-lg-3 mb-md-3'>
        <Col md={6}>
          <FormGroup>
            <Label for="organization">
            Organization( Optional)
            </Label>
            <Input
              id="organization"
              name="organization"
              placeholder="The leagues"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="phoneNumber">
            Whatsapp Number
            </Label>
            <Input
              id="phoneNumber"
              name="phoneNumber"
              placeholder="(234) 7054476646"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mb-0 mb-lg-3 mb-md-3'>
       
        <Col md={6}>
          <FormGroup>
            <Label for="address">
            Whatsapp Number
            </Label>
            <Input
              id="address"
              name="address"
              placeholder="No 123 Ave Road Leaf Village"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="partnershipMode">
            How You want to Partner
            </Label>
            <Input
              id="partnershipMode"
              name="partnershipMode"
              placeholder="partnership ways"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className='mt-4 mb-4 mb-2 text-end  text-md-start'>
        <Button className='button'>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default PartnershipForm;
