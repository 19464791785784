import Hero from "../shared/Hero";
import partnershipHeroBg from '../assets/partnership-image.svg'
import TestimonyForm from "../shared/TestimonyForm";
import Testimonials from "../shared/Testimonials";
const testimonialData = [
    {
      title: "God changed my life",
      text: "God has been faithful. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium is mani doloremi quelaudantium, totamamiun isi remaperiam eaque ipsa quae ab illo.",
      author: "Adewale Mariam",
    },
    {
      title: "New Job, New Salary",
      text: "I got a new job with a better salary. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium is mani doloremi quelaudantium, totamamiun isi remaperiam eaque ipsa quae ab illo.",
      author: "Adewale Mariam",
    },
    {
      title: "Pregnant after 5 years",
      text: "I got pregnant after 5 years of trying. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium is mani doloremi quelaudantium, totamamiun isi remaperiam eaque ipsa quae ab illo.",
      author: "Adewale Mariam",
    },
  ];
  
  
  
  
const TestimonyPage = () => {
    return (
        <div>
            <Hero className="prayer-request-page-hero" backgroundImage={partnershipHeroBg}>
                <div className='message-small-card p-5 mb-0 mx-0 mt-0'>
                    <div className="message-content">
                        <p className="message-title my-1">Revelation 12:11</p>
                        <p className="message-passage my-1">And they overcame him by the blood of the Lamb, and by the word of their testimony; and they loved not their lives unto the death</p>
                    </div>
                </div>
            </Hero>
            <h1 className='text-center prayer-request-title pt-5 pb-3'>Share your Testimony</h1>
            <div className='prayer-request-message-text'>
                <p className=''>
                    we invite you to partner with our ministry and help us make a positive impact in the lives of those we serve. Your support will make a significant difference in our ability to fulfill our mission of spreading the love of Christ to those in need.
                </p>
            </div>
            <div className='mt-5 px-5 mb-5'>
                <TestimonyForm />
            </div>
         <Testimonials testimonialData={testimonialData} />;
        </div>
    )
}


export default TestimonyPage;
