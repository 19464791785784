import { FC, ReactElement } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Hero from '../shared/Hero';
import outOfTheOrdinaryHeroBg from '../assets/out-of-the-ordinary-hero.svg'
import outOfOrdinaryCard from '../assets/out-of-the-ordinary-card.svg'
import MessageCard from '../shared/SermonCard';
type SermonProps = {
  name: string;
  id: number;
}
type BibleVerse = {
  title: string;
  date: string;
  author: string;
  verse: string;
  imageUrl: string;
};


const bibleVerses: BibleVerse[] = [
  {
    title: 'Out of the ordinary',
    date: 'March 19, 2023',
    author: 'John Doe',
    verse: 'John 3:16',
    imageUrl: outOfOrdinaryCard,
  },
  {
    title: 'Out of the ordinary',
    date: 'March 18, 2023',
    author: 'Jane Smith',
    verse: 'Proverbs 3:5-6',
    imageUrl: outOfOrdinaryCard,
  },
  {
    title: 'Out of the ordinary',
    date: 'March 17, 2023',
    author: 'Bob Johnson',
    verse: 'Psalm 23:1',
    imageUrl: outOfOrdinaryCard,
  },
  {
    title: 'Out of the ordinary',
    date: 'March 16, 2023',
    author: 'Mary Brown',
    verse: 'Ephesians 2:8',
    imageUrl: outOfOrdinaryCard,
  },
  {
    title: 'Out of the ordinary',
    date: 'March 15, 2023',
    author: 'James Wilson',
    verse: 'John 11:25-26',
    imageUrl: outOfOrdinaryCard,
  },
  {
    title: 'Out of the ordinary',
    date: 'March 14, 2023',
    author: 'Sarah Lee',
    verse: 'Jeremiah 29:11',
    imageUrl: outOfOrdinaryCard,
  },
  {
    title: 'Out of the ordinary',
    date: 'March 13, 2023',
    author: 'David Jones',
    verse: 'Romans 3:23-24',
    imageUrl: outOfOrdinaryCard,
  },
  {
    title: 'Out of the ordinary',
    date: 'March 12, 2023',
    author: 'Anna Green',
    verse: 'Matthew 28:19-20',
    imageUrl: outOfOrdinaryCard,
  },
  {
    title: 'Out of the ordinary',
    date: 'March 12, 2023',
    author: 'Anna Green',
    verse: 'Matthew 28:19-20',
    imageUrl: outOfOrdinaryCard,
  }
]

const SermonPage: FC<SermonProps> = (): ReactElement => {
  return <div>
    <Hero className="sermon-page-hero" backgroundImage={outOfTheOrdinaryHeroBg}>
      <div className='message-small-card p-md-5 p-lg-5 px-3 mb-0 mx-0 mt-0'>
        <header>
          <h2 className="message-title my-0">Out of the ordinary</h2>
          <p className="message-date my-1">Jan 23, 2023</p>
        </header>
        <div className="message-content">
          <p className="message-passage my-1">Matthew 3: 10 - 21</p>
          <p className="message-speaker my-1">By Pst Yemi Adekola</p>
        </div>
      </div>
    </Hero>
    <Container>
      <Row className='mt-5 px-md-5 px-lg-5'>
        {bibleVerses.map((verse, index) => (
          <Col key={index} xs="12" md="4" className='mb-5'>
            <MessageCard
              title={verse.title}
              date={verse.date}
              author={verse.author}
              verse={verse.verse} imageUrl={verse.imageUrl} />
          </Col>
        ))}
        <div className='text-md-center text-center mb-5'>
          <Button className='button'>Show More</Button>
        </div>
      </Row>
    </Container>
  </div>
}

export default SermonPage
