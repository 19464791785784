import Hero from '../shared/Hero';
import partnershipHeroBg from '../assets/partnership-image.svg'
import publicityPartnership from '../assets/partnership-publicity.svg'
import welfarePartnership from '../assets/partnership-welfare.svg'
import projectPartnership from '../assets/partnership-project.svg'
import { Col, Row } from 'reactstrap';
import PartnershipForm from '../shared/PartnershipForm';
import BibleVerseCard from '../shared/BibleVerseCard';

const PartnershipPage = () => {
    return (
        <div className='partnership-page'>
            <Hero className="partnership-page-hero" backgroundImage={partnershipHeroBg}>
            <BibleVerseCard verse="Psalm 85:10 (NIV)" passage="Love and faithfulness meet together; righteousness and peace kiss each other." />
            </Hero>
            <div className=''>
                <h1 className='text-center partnership-title pt-5 pb-3'>Partner with us to help our community</h1>
                <div className='partnership-message-text'>
                    <p>

                        we invite you to partner with our ministry and help us make a positive impact in the lives of those we serve. Your support will make a significant difference in our ability to fulfill our mission of spreading the love of Christ to those in need.
                    </p>

                    <p>

                        Our ministry is dedicated to providing essential resources to underserved communities, including food, shelter, and spiritual guidance. We believe that everyone deserves access to these basic necessities and that we can make a meaningful impact through our work.
                    </p>

                    <p>

                        By partnering with us, you will help us continue to serve those in need and expand our reach to even more communities. Your contributions will help us provide food to the hungry, shelter to the homeless, and hope to those who are struggling.
                    </p>

                    <p>

                        We understand that everyone's circumstances are different, and we appreciate any level of support that you are able to provide. Whether it's a one-time donation or ongoing monthly support, every dollar makes a difference.
                    </p>
                    <p>

                        As a thank you for your partnership, we will provide regular updates on our progress, including stories of how your contributions are making an impact in the lives of those we serve. We will also include you in our prayers and ask for God's blessings on your life and the lives of your loved ones.
                    </p>

                </div>
            </div>
            <h3 className='text-center partnership-scope-title py-5'>Scope of Partnership</h3>
            <div className='mt-5 px-5 mb-5'>
                <Row className='mb-5'>
                    <Col xs="12" md="4" className='partnership-image text-center'>
                    <h4 className='partnership-item-title'>Publicity Partnership</h4>
                        <img src={publicityPartnership} alt='' />
                    </Col>
                    <Col xs="12" md="8" className='pt-md-5 pt-3 ps-md-5 pt-lg-5 ps-lg-5'>
                        <div>
                            <h4 className='partnership-item-title'>Publicity Partnership</h4>
                            <div className='partnership-item-text'>
                                <p>
                                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officiaconsequi atduis enim velit mollit.
                                </p>
                                <ul>
                                    <li>
                                        Evangelism
                                    </li>
                                    <li>Missions</li>
                                    <li>Spreading the message </li>
                                </ul>
                            </div>
                        </div>

                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col xs="12" md="8" className='pt-md-5 pe-md-5 pt-lg-5 pe-lg-5 pt-3 order-md-0 order-lg-0 order-1'>
                        <div>
                            <h4 className='partnership-item-title'>Welfare Partnership</h4>
                            <div className='partnership-item-text'>
                                <p>
                                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officiaconsequi atduis enim velit mollit.
                                    The ministry helps:
                                </p>
                                <ul>
                                    <li>
                                        Orphans
                                    </li>
                                    <li>Widows</li>
                                    <li>Less Privilege</li>
                                    <li>The needy</li>

                                </ul>
                            </div>
                        </div>

                    </Col>
                    <Col xs="12" md="4" className='partnership-image'>
                    <h4 className='partnership-item-title text-center'>Welfare Partnership</h4>
                        <img src={welfarePartnership} alt='' />
                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col xs="12" md="4" className='partnership-image' >
                    <h4 className='partnership-item-title text-center'>Project Partnership</h4>
                        <img src={projectPartnership} alt='' />
                    </Col>
                    <Col xs="12" md="8" className='pt-md-5 pt-3 ps-md-5 pt-lg-5 ps-lg-5'>
                        <div>
                            <h4 className='partnership-item-title'>Project Partnership</h4>
                            <div className='partnership-item-text'>
                                <p>
                                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officiaconsequi atduis enim velit mollit.
                                </p>
                            </div>
                        </div>

                    </Col>
                </Row>
                <div>

                    <h3 className='text-center partnership-form-title'>Partnership Form</h3>
                    <p className='partnership-form-text text-center'>
                        Thank you for your interest in partnering with our ministry! Your support will make a meaningful impact in the lives of those we serve.
                    </p>
                    <PartnershipForm />
                </div>
            </div>
        </div>
    )
};

export default PartnershipPage;
