import { Container, Row } from 'reactstrap';
import Hero from '../shared/Hero';
import backgroundImage from '../assets/contact-us-hero.svg'
import FooterHero from '../shared/FooterHero';
import ContactForm from '../shared/ContactForm';

const ContactPage = () => {
    return (
        <div>
            <div className='px-0 px-md-5 px-lg-5 mb-md-5 mb-lg-5 mx-0'>
                <Hero className="contact-us-page-hero" backgroundImage={backgroundImage}>
                </Hero>
            </div>
            <div className='px-3 px-md-5 px-lg-5 mb-5'>
                <h2 className='contact-us-page-title text-center mt-0 pt-0'>Contact Us</h2>
                <Container className='px-md-5 px-lg-5 mb-5'>
                    <p className='contact-us-text px-md-5 px-lg-5'>
                        We are thrilled to hear from you! Our team is here to assist you with any questions or concerns you may have. Whether you're looking for more information about our products or services, or simply want to share your feedback, we're always happy to hear from you. Don't hesitate to get in touch with us and we'll get back to you as soon as possible.
                    </p>
                </Container>
                <ContactForm />
            </div>
            <Row className='mt-md-5 mt-lg-5 mt-4 mx-0'>
                <FooterHero className="page-footer-hero-bg mb-0 pt-0" />
            </Row>
        </div>
    )
}

export default ContactPage;
