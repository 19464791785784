import Hero from '../shared/Hero';
import IamNewHeroBg from '../assets/iam-new-hero-bg.svg'
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import paypalLogo from '../assets/PayPal.svg'
import paystackLogo from '../assets/paystack.svg'
import flutterLogo from '../assets/flutterwave.svg'
import AccordionComponent from '../shared/AccordionComponent';
import { useState } from 'react';
import { IonIcon } from '@ionic/react';
import {  arrowForwardOutline } from 'ionicons/icons';
import BibleVerseCard from '../shared/BibleVerseCard';
const GivenPage = () => {
    const [open, setOpen] = useState('');
    const toggle = (id: string) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };
    const items = [
        {
            id: '1',
            title: 'Guarantee Trust Bank PLC',
            content: (
                <>
                    <p>Account Name : Anagkazo Mission International
                    </p>
                    <p>
                        Account Number : 0694730026
                    </p>
                    <p>
                        Sort Code : 058113127
                    </p>
                    <p>
                        Swift Code : GTBINGLA</p>
                </>
            ),
        },
        {
            id: '2',
            title: 'Zenith Bank PLC',
            content: (
                <>
                    <p>Account Name : Anagkazo Mission International
                    </p>
                    <p>
                        Account Number : 1016733216
                    </p>
                    <p>
                        Swift Code : ZEIBNGLA
                    </p>
                </>
            ),
        },
        {
            id: '3',
            title: 'CitiBank New York ( USD )',
            content: (
                <>
                    <p>Account Name : Anagkazo Mission International
                    </p>
                    <p>
                        Account Number : 0700239497
                    </p>
                    <p>
                        Sort Code : 058113127
                    </p>
                    <p>
                        Swift Code : CITIUS33</p>
                    <p>
                        ABA Number: 021000089
                    </p>
                    <p>
                        IBAN: 36129295</p>
                    <p>Beneficiary’ Swift code: GTBINGLA
                    </p><p>Beneficiary’ Bank Name/Address:
                        Guarantee Trust Bank PLC, Lagos,
                        Nigeria. </p>
                </>
            ),
        },
        {
            id: '4',
            title: 'Guarantee Trust Bank PLC ( GBP )',
            content: (
                <>
                    <p>Account Name : Anagkazo Mission International
                    </p>
                    <p>
                        Account Number : 0700239507
                    </p>
                    <p>
                        Sort Code : 185008
                    </p>
                    <p>
                        Swift Code : CITIGB2L</p>
                    <p>
                        ABA Number: 021000089
                    </p>
                    <p>
                        IBAN: GB72CITI185008500808315795</p>
                    <p>Beneficiary’ Swift code: GTBINGLA
                    </p><p>Beneficiary’ Bank Name/Address:
                        Guarantee Trust Bank PLC, Lagos,
                        Nigeria. </p>
                </>
            ),
        }
    ];
    const paypal = [
        {
            "title": "Paypal",
            "logo": paypalLogo,
            "text": "You will be redirected to the PayPal website to complete your transaction",
            "link": "https://stripe.com/pay"
        },

        {
            "title": "Paystack",
            "logo": paystackLogo,
            "text": "You will be redirected to the Paystack website to complete your transaction",
            "link": "https://www.paypal.com/pay"
        },
        {
            "title": "Flutterwave",
            "logo": flutterLogo,
            "text": "You will be redirected to the Flutterwave website to complete your transaction",
            "link": "https://venmo.com/pay"
        }
    ]



    return (
        <>
            <Hero className="prayer-request-page-hero" backgroundImage={IamNewHeroBg}>
            <BibleVerseCard verse="Psalm 85:10 (NIV)" passage="Love and faithfulness meet together; righteousness and peace kiss each other." />
            </Hero>
            <h1 className='text-center iam-new-page-title pt-5 pb-3'>Giving: a joyful response to a life changed by the gospel</h1>
            <div className='give-message-text'>
                <p className=''>
                    When we give, we are able to share the blessings that have been bestowed upon us with those in need. We have the power to make a positive impact on the lives of others, and through our generosity, we can spread joy and hope in the world.
                </p>
                <p className=''>
                    Imagine the impact we can make together! Every dollar donated can change a life and bring about incredible transformation. So let's join hands and give with joyful hearts, knowing that our gifts will make a difference in the world.
                </p>
                <p className=''>
                    We thank you for your continued support and generosity towards our mission. Together, we can create a world filled with love, kindness, and hope.
                </p>
            </div>
            <div className='px-md-5 px-lg-5 px-4 mb-5 pt-5'>
                <Row className='mb-5 pt-5 px-0 mx-3 mb-5'>
                    <h3 className='text-center'>Our Payment Methods</h3>
                    <div>
                        <AccordionComponent items={items} toggle={toggle} open={open} flush={true} />
                    </div>
                </Row>
            </div>
            <div className='px-md-5 px-lg-5 px-0 mb-5 pt-5 payment'>
                <Row className='mb-5 pt-5 px-4 mx-3 mb-5'>
                    <h3 className='text-center'>Online Payment</h3>
                    <p className='give-message-text w-md-75 w-lg-75 w-100'> For a quick and convenient way to make payments for your partnership. To get started, please follow any of the convenient method and we would receive it.  </p>
                    <ListGroup className='list-group-flush list-messages-items mt-5 mb-5 px-md-0 px-3'>
                        {paypal.map((item, index) => (
                            <ListGroupItem
                                key={index}
                                className='d-flex'
                                tag="a"
                                href={item.link}
                                action
                            >
                                    <Col md={1}>
                                        <div className='payment-logo me-md-4 me-2'>
                                            <img src={item.logo} alt={`${item.title} logo`} />
                                        </div>
                                    </Col>
                                <div className="d-md-flex flex-grow-md-1 flex-grow-lg-1">
                                    <div className="px-md-2 px-lg-2 pe-3">
                                        <p className="list-title ps-0 m-0">{item.title}</p>
                                    </div>
                                    <div className="px-md-2 px-lg-2 px-md-2">
                                        <p className="list-text ps-md-5 ps-lg-5 m-0">{item.text}</p>
                                    </div>
                                </div>
                                    <div className="px-md-2 px-lg-2 px-md-2 pe-3">
                                        <span><IonIcon icon={arrowForwardOutline} /></span>
                                    </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Row>
            </div>
        </>

    )
}


export default GivenPage;
