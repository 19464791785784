import { useMatch, Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';

type NavItemProps = {
  to: string;
  label: string;
  className?: string;
}

const NavigationItem = ({ to, label, className }: NavItemProps) => {
  const isActive = useMatch(to);

  return (
    <NavItem className={className}>
      <NavLink tag={Link} to={to} className={isActive ? 'active' : ''}>
        {label}
      </NavLink>
    </NavItem>
  );
};

export default NavigationItem;
