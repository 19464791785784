import { useState, } from "react";
import { Link } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    Button
} from 'reactstrap';
import logo from '../assets/anagkazo.svg'
import ButtonNavLink from "./ButtonNavLink";
import NavigationItem from "./NavigationItem";
const HeaderNavBar = () => {
    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => setCollapsed(!collapsed);
    console.log(collapsed)

    return (
        <div>
            <Navbar className="ps-3 pe-5 border" dark expand="md">
                <NavbarBrand tag={Link} to='/'>
                    <img src={logo} alt="Anagkazo logo" width="90" height="90" />
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="me-0" />
                <Collapse isOpen={!collapsed} navbar>
                    <Nav className="navbar-links text-uppercase" navbar>
                        <NavigationItem className="pe-2" to="/" label="Home" />
                        <NavigationItem className="pe-2" to="/about" label="About Us" />
                        <NavigationItem className="pe-2" to="/partnership" label="Partnership" />
                        <NavigationItem className="pe-2" to="/sermons" label="Sermons" />
                        <NavigationItem className="pe-2" to="/contact" label="Contact Us" />
                        <ButtonNavLink to="/give" exact>
                            <Button className="button fs-5">Online Giving</Button>
                        </ButtonNavLink>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default HeaderNavBar;
