import { IonIcon } from '@ionic/react';
import {  chevronForwardOutline } from 'ionicons/icons';
import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

type ListItemProps = {
  date: string;
  title: string;
  link: string;
};

const CustomListGroup: React.FC<{ items: ListItemProps[] }> = ({ items }) => {
  return (
    <ListGroup className='list-group-flush list-messages-items'>
      {items.map((item, index) => (
        <ListGroupItem
          key={index}
          className="d-flex px-0 justify-content-between align-items-center"
          tag="a"
          href={item.link}
          action
        >
          <div>
            <p className="list-date p-0 m-0">{item.date}</p>
            <p className="list-title  p-0 m-0">{item.title}</p>
          </div>
           <span><IonIcon icon={chevronForwardOutline} /></span>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

export default CustomListGroup;
