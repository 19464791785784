import { IonIcon } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import React from 'react';
import { NavLink as RRNavLink } from "react-router-dom";
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, NavLink } from 'reactstrap';

type MessageCardProps = {
    imageUrl: string;
    title: string;
    date: string;
    verse: string;
    author: string;
    className?: string;
};

const MessageCard: React.FC<MessageCardProps> = ({ imageUrl, title, date, verse, author, className }) => {
    const cardClasses = `message-card ${className ? className : ''}`;

    return (
        <Card className={cardClasses}>
            <CardImg top className="message-card-image" src={imageUrl} alt="Card image cap" />
            <NavLink className="pt-1" tag={RRNavLink} to="/sermons/01">
                <CardBody>
                    <CardTitle className="message-card-title">{title}</CardTitle>
                    <CardSubtitle className="message-card-date">{date}</CardSubtitle>
                    <CardText className="message-card-verse">
                        {verse}
                    </CardText>
                    <CardText className="message-card-author">
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                By {author}
                            </div>
                            <div className='px-0 mx-0 align-self-end'><IonIcon icon={arrowForward} /></div>
                        </div>
                    </CardText>
                </CardBody>
            </NavLink>
        </Card>
    );
};

export default MessageCard;
